<template>
    <b-row style="padding: 0 16px;">
        <b-col cols="12" class="pb-1">
            <div class="card-picker h-100 overflow-hidden" v-ripple @click="$router.replace({ name: 'user-statistic-cloud', params: { group: 'cloud' } })" >
                <feather-icon icon="CloudIcon" size="32" class="text-primary"/>
                <p class="header">{{$t('cloud.cloud')}}</p>
                <span class="text">{{$t('cloud.stat')}}</span>
            </div>
        </b-col>
        <b-col cols="12" class="pb-1">
            <div class="card-picker h-100 overflow-hidden" v-ripple @click="$router.replace({ name: 'user-statistic-miners', params: { group: 'miners' } })">
                <feather-icon icon="CpuIcon" size="32" class="text-primary"/>
                <p class="header">{{$t('miners.miners')}}</p>
                <span class="text">{{$t('miners.stat')}}</span>
            </div>
        </b-col>
    </b-row>
</template>

<script>

    export default {

        data() {
            return {}
        },
        methods: {

        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>